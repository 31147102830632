import { Requestor, StorageBackend } from '@openid/appauth';
import { AuthService, Browser } from 'ionic-appauth';

export class AuthServiceWrapper extends AuthService {
  async removeToken(): Promise<void> {
    await this.storage.removeItem('token_response');
  }
}

export let authFactory = (requestor: Requestor, browser: Browser, storage: StorageBackend) => {
  return new AuthServiceWrapper(browser, storage, requestor);
};
