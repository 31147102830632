<itl-input-button-template
  [control]="ngControl"
  [title]="title"
  [hasErrors]="ngControl ? ngControl.invalid : false"
  [placeholder]="placeholder"
  [value]="value | selectionDisplayText: values:compareFn"
  [noIcon]="noIcon"
  [icon]="icon"
  [disabled]="disabled"
  [required]="required"
  [description]="description"
  (buttonPressed)="openSelect()"
  [cutText]="cutText"
>
  <div class="deleteButton" (click)="removeSelected($event)" *ngIf="value != undefined && !disabled && !noDeleteButton">
    <ion-icon name="alberta-delete"></ion-icon>
  </div>
</itl-input-button-template>
