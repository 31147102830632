import { ArticleStatus, IArticle } from '@alberta/konexi-shared';
import moment from 'moment';

export function filterUnlockedArticles(article: IArticle): boolean {
  if (
    article.status === ArticleStatus.Available ||
    article.status === ArticleStatus.Delayed ||
    article.status == null
  ) {
    return true;
  }

  const isStillAvailable = moment(new Date(article.unavailableFrom).toDateString()).isAfter(
    new Date().toDateString(),
    'days'
  );
  const isBetween = moment(new Date()).isBetween(
    moment(new Date(article.unavailableFrom)),
    moment(new Date(article.unavailableTo))
  );

  return (
    (article.status === ArticleStatus.DontUse && !isBetween) ||
    ((article.status === ArticleStatus.Locked || article.status === ArticleStatus.NotAvailable) && isStillAvailable)
  );
}

export function sortArticles(articles) {
  if (!articles) return [];
  return articles.sort((a, b) => {
    if (a.status === ArticleStatus.Available && b.status !== ArticleStatus.Available) {
      return -1;
    }
    if (a.status !== ArticleStatus.Available && b.status === ArticleStatus.Available) {
      return 1;
    }
    return 0;
  });
}
