<itl-input-button-template
  [control]="ngControl"
  [title]="title"
  [hasErrors]="ngControl ? ngControl.invalid : false"
  (buttonPressed)="showCheckbox()"
  [placeholder]="placeholder"
  [value]="
    value && value.length > 1 && multipleSelectedText
      ? multipleSelectedText
      : (value | checkboxSelectDisplayPipe: selectionItems : compareFn)
  "
  [title]="title"
  [required]="required"
  [icon]="icon"
  [disabled]="disabled"
  [description]="description"
>
  <div
    class="deleteButton"
    (click)="removeSelected($event)"
    *ngIf="value != undefined && !disabled && showDeleteButton"
  >
    <ion-icon name="alberta-delete"></ion-icon>
  </div>
</itl-input-button-template>
