import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'itl-button-small',
  template: '<ng-content></ng-content>',
  styleUrls: ['./button-small.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ButtonSmallComponent implements OnChanges {
  // Set to "block" for a full-width with left and right borders.
  @Input() expand: 'block' | undefined;
  @Input() disabled: boolean = false;
  @Input() noBorder: boolean = false;

  @HostBinding('class.displayBlock') displayBlock = false;
  @HostBinding('class.disabled') disabledStyle = false;
  @HostBinding('class.noBorder') noBorderStyle = false;

  ngOnChanges(changes: SimpleChanges): void {
    this.displayBlock = this.expand === 'block';
    this.disabledStyle = this.disabled;
    this.noBorderStyle = this.noBorder;
  }
}
