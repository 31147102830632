import { CommonModule } from "@angular/common";
import { ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnChanges, Optional, Output, SimpleChanges } from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { SelectionItem } from "src/app/shared/models/selectionItem";
import { SelectionService } from "../../services/selection.service";
@Component({
    selector: 'itl-button-selection',
    templateUrl: `./button-selection.component.html`,
    styleUrls: ['./button-selection.component.scss'],
    imports: [CommonModule],
    standalone: true,
    host: {
        '(click)' : 'openSelect()'
    }
})
export class ButtonSelectionComponent implements OnChanges, ControlValueAccessor {

    
    @Input() title: string = '';
    @Input() unit: string = '';
    @Input() icon: string;
    @Input() noIcon: boolean = false;
    @Input() noBorder: boolean = false;
    @Input() required: boolean;
    @Input() possibleSelections: SelectionItem[] = [];
    @Input() disabled: boolean = false;
    @Output() change: EventEmitter<any> = new EventEmitter<any>

    public currentSelection: SelectionItem = new SelectionItem('', '');
    
    private onChange = (text: string) => {};
    private onTouched = () => {};

    @HostBinding('class.disabled') disabledStyle = false;
    @HostBinding('class.noBorder') noBorderStyle = false;
    constructor(
        private _selectionService: SelectionService,
        @Optional() public ngControl: NgControl,
        private _changeDetectionRef: ChangeDetectorRef
    ){
        if(this.ngControl){
            ngControl.valueAccessor = this;
        }
    }
    ngOnChanges(changes: SimpleChanges): void {
        this.disabledStyle = this.disabled;
        this.noBorderStyle = this.noBorder
    }

    writeValue(obj: any): void {
        if(obj){
          const item = this.possibleSelections.find(item => item.id === obj);
          this.currentSelection = new SelectionItem(item.id, item.value);
          this._changeDetectionRef.markForCheck();
        }
    }
    
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
    public async openSelect(){
        const selectionResult = await this._selectionService.openSelection(this.currentSelection, this.possibleSelections, this.title)
          if (selectionResult && selectionResult.canceled === false) {
            const targetItem = this.possibleSelections.find(item => item.id === selectionResult.value);
            this.currentSelection = targetItem;
            this.onChange(this.currentSelection.id)
            this.change.emit(this.currentSelection.id);
          }
    }
}