import { Component, EventEmitter, Input, Optional, Output } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { SelectionItem } from 'src/app/shared/models/selectionItem';

import { IBaseInputContract } from '../contracts/base-input';
import { SelectModalComponent } from '../select-modal/select.modal.component';

@Component({
  selector: 'itl-checkbox-select',
  styleUrls: ['./checkbox-select.component.scss'],
  templateUrl: './checkbox-select.component.html',
})
export class CheckboxSelectComponent implements IBaseInputContract, ControlValueAccessor {
  @Input() public title: string;
  @Input() public headerText: string;
  @Input() public selectionItems: SelectionItem[];
  @Input() public multipleSelectedText: string;
  @Input() public compareFn: any;
  @Input() public placeholder = '';
  @Input() public required = false;
  @Input() public icon: string;
  @Input() public disabled: boolean;
  @Input() public description: string;
  @Input() public showSearch: boolean;
  @Input() public showDeleteButton = false;
  @Output() public change: EventEmitter<[]> = new EventEmitter<[]>();

  public value: any;
  private onChange = (text: string) => {};
  private onTouched = () => {};

  constructor(
    private _translate: TranslateService,
    private _modalController: ModalController,
    @Optional() public ngControl: NgControl
  ) {
    if (this.ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  public async showCheckbox(): Promise<void> {
    // create options
    const checkboxoptions = [];
    this.selectionItems.forEach(value => {
      checkboxoptions.push({
        type: 'checkbox',
        label: value.value,
        value: value.id,
        checked: this.checkifChecked(value.id),
      });
    });
    // create Modal
    const modal = await this._modalController.create({
      component: SelectModalComponent,
      backdropDismiss: false,
      componentProps: {
        header: this.headerText,
        inputs: checkboxoptions,
        showSearch: this.showSearch,
        buttons: [
          {
            text: this._translate.instant('CANCEL_BUTTON'),
          },
          {
            text: this._translate.instant('DONE_BUTTON'),
            handler: data => {
              if (!this.value) {
                this.value = [];
              }
              this.value = data;
              this.updateChanges();
              this.change.emit(data);
            },
          },
        ],
      },
      cssClass: 'select-modal',
    });
    await modal.present();
    await modal.onDidDismiss();
    this.onTouched();
  }

  private checkifChecked(id: any): boolean {
    return (
      this.value &&
      this.value.find(item => {
        if (this.compareFn) {
          return this.compareFn(id, item);
        }
        return id === item;
      }) !== undefined
    );
  }

  public updateChanges() {
    this.onChange(this.value);
  }

  writeValue(obj: any): void {
    this.value = obj;
    this.updateChanges();
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public removeSelected($event): void {
    if ($event) {
      $event.stopPropagation();
    }
    this.writeValue(null);
    this.onChange(this.value);
    this.change.emit(this.value);
  }
}
