import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Platform } from '@ionic/angular';
import { MbscDatepickerCloseEvent } from '@mobiscroll/angular';
import { Subject } from 'rxjs';
import { IBaseInputContract } from '../contracts/base-input';
export enum DateType {
  DateTime,
  Date,
  Time,
}

export enum DatePickerControls {
  Date = 'calendar',
  Time = 'time',
}

@Component({
  selector: 'itl-date-time',
  templateUrl: 'date-time.component.html',
  styleUrls: ['date-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateTimeComponent implements OnInit, OnDestroy, IBaseInputContract, ControlValueAccessor {
  @Input() public title = '';
  @Input() public placeholder = '';
  // Min Max wird nur auf das Datum nicht auf die Zeit angewendet
  @Input() public min = new Date('1900-01-01');
  @Input() public max = null;
  @Input() public preSelection: Date;
  @Input() public type: DateType = DateType.Date;
  // TODO add Clear Button
  @Input() public showClearButton = false;
  @Input() public required = false;
  @Input() public icon: string;
  @Input() public disabled: boolean;
  @Input() public noIcon = false;
  @Input() public description: string;
  @Input() public dateFormatter = 'dd.MM.yyyy';
  @Input() public minTime: string | Date;
  @Input() public maxTime: string | Date;
  @Input() public noDeleteButton = false;
  @Input() public returnType: 'date' | 'string' = 'date';
  @Output() public change: EventEmitter<Date | string> = new EventEmitter<Date | string>();

  private _dateTimePicker: any;
  @ViewChild('mobiscrollDateTimePicker', { static: false }) set mobiscrollDateTimePicker(
    mobiscrollDateTimePicker: any
  ) {
    if (mobiscrollDateTimePicker) {
      this._dateTimePicker = mobiscrollDateTimePicker;
    }
  }
  private _timePicker: any;
  @ViewChild('mobiscrollTimePicker', { static: false }) set mobiscrollTimePicker(mobiscrollTimePicker: any) {
    if (mobiscrollTimePicker) {
      this._timePicker = mobiscrollTimePicker;
    }
  }
  @ViewChild('picker', { static: true }) public timePicker: any;
  @ViewChild('timeInput', { static: true }) public timeInputElement: any;

  public dateTimePickerControls: DatePickerControls[];
  public date: Date | string;
  public DateType = DateType;
  private _ngUnsubscribe = new Subject();

  private onChange = (date: Date | string) => {};
  private onTouched = () => {};

  constructor(
    public plt: Platform,
    @Optional() public ngControl: NgControl,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.setControls();
  }

  private setControls() {
    if (this.type === DateType.Date) {
      this.dateTimePickerControls = [DatePickerControls.Date];
    } else if (this.type === DateType.Time) {
      this.dateTimePickerControls = [DatePickerControls.Time];
    } else {
      this.dateTimePickerControls = [DatePickerControls.Date, DatePickerControls.Time];
    }
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe.next(null);
    this._ngUnsubscribe.complete();
  }

  // Focustrap of ion modal messes with the focus of mobiscroll
  // We need to delete it as long as the datepicker is open
  private disableMobiScrollFocusTrap() {
    const ionModals = window.document.body.querySelectorAll('ion-modal');
    if (ionModals && ionModals.length > 0) {
      ionModals.forEach(modal => {
        modal.classList.add('ion-disable-focus-trap');
      });
    }
  }

  private enableMobiScrollFocusTrap() {
    const ionModals = window.document.body.querySelectorAll('ion-modal');
    if (ionModals && ionModals.length > 0) {
      ionModals.forEach(modal => {
        modal.classList.remove('ion-disable-focus-trap');
      });
    }
  }

  openMobiScroll() {
    this.disableMobiScrollFocusTrap();
    this._changeDetectorRef.markForCheck();
    this._dateTimePicker.open();
  }
  clearDate(event: Event): void {
    event.stopPropagation();
    this.date = null;
    this.change.emit(null);
  }
  cancel() {
    this.markAsTouched();
  }

  public dateChanged(value: MbscDatepickerCloseEvent) {
    this.enableMobiScrollFocusTrap();
    if (!value.value) {
      this.date = undefined;
    } else {
      this.date = new Date(value.value.toString());
    }
    this.change.emit(this.date);
    this.updateChanges();
  }
  // We dont want the change event to bubble up to the parent
  public handleChange(event: any) {
    event.stopPropagation();
  }

  public timeChanged(value: MbscDatepickerCloseEvent) {
    this.enableMobiScrollFocusTrap();

    if (!value.value) {
      this.date = undefined;
    } else {
      this.date = new Date(`1970-01-01 ${value.value}`);
    }
    this.change.emit(this.date);
    this.updateChanges();
  }

  openTimePicker() {
    this.disableMobiScrollFocusTrap();
    this._changeDetectorRef.markForCheck();

    this._timePicker.open();
  }

  public markAsTouched(): void {
    this.onTouched();
  }

  public updateChanges() {
    this.onChange(this.date);
  }

  // ControlValueAccessor interface
  public writeValue(value: any) {
    if (!value || typeof value === 'string' || value instanceof Date) {
      this.date = value;
    }
    this._changeDetectorRef.markForCheck();
  }

  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  public removeInput($event): void {
    this.clearDate($event);
    this.updateChanges();
  }
}
