import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, Optional } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { SelectionItem } from 'src/app/shared/models/selectionItem';

@Component({
  selector: 'itl-tab-control',
  templateUrl: './tab-control.component.html',
  styleUrls: ['./tab-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabControlComponent implements ControlValueAccessor {
  @Input() options: SelectionItem[];
  public value: string;
  private onChange = (text: string) => {};
  private onTouched = () => {};

  constructor(
    @Optional() public ngControl: NgControl,
    private _changeDetectionRef: ChangeDetectorRef
  ) {
    if (this.ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  public selectItem(id: string) {
    this.value = id;
    this.onChange(id);
  }

  public markAsTouched(): void {
    this.onTouched();
  }

  writeValue(obj: any): void {
    this.value = obj;
    this._changeDetectionRef.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
