import { ChangeDetectorRef, Component, EventEmitter, Input, Optional, Output } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { SelectionItem } from 'src/app/shared/models/selectionItem';

import { SelectionService } from '../../services/selection.service';
import { IBaseInputContract } from '../contracts/base-input';

@Component({
  selector: 'itl-selection',
  templateUrl: './selection.component.html',
  styleUrls: ['./selection.component.scss'],
})
export class SelectionComponent implements IBaseInputContract, ControlValueAccessor {
  @Input() public title: string;
  @Input() public values: SelectionItem[] = [];
  @Input() public icon: string;
  @Input() public placeholder = '';
  @Input() public noIcon = false;
  @Input() public cutText = false;
  @Input() public noDeleteButton = false;
  // deaktiviert den Input
  @Input() public disabled = false;
  // Zeigt einen roten Stern neben dem Titel an
  @Input() public required = false;
  // Funktion zum vergleichen von Selection Items
  @Input() public compareFn = null;
  // Funktion mit Boolschen Wert als Rückgabe -> Wenn True wird der Wert geändert
  @Input() public changeAllowed = null;
  @Input() public description: string;
  @Input() public showSearch: boolean;
  
  @Output() public change: EventEmitter<any> = new EventEmitter<any>();

  public value: any;
  private onChange = (text: string) => {};
  private onTouched = () => {};

  constructor(
    private _selectionService: SelectionService,
    @Optional() public ngControl: NgControl,
    private _changeDetectionRef: ChangeDetectorRef
  ) {
    if (this.ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  public markAsTouched(): void {
    this.onTouched();
  }

  async openSelect() {
    this.markAsTouched();
    const selectionResult = await this._selectionService.openSelection(this.value, this.values, this.title, {
      compareFn: this.compareFn,
      changeAllowed: this.changeAllowed,
      showSearch: this.showSearch,
    });
    if (selectionResult && selectionResult.canceled === false) {
      this.value = selectionResult.value;
      this.onChange(this.value);
      this.change.emit(selectionResult.value);
    }
  }

  writeValue(obj: any): void {
    this.value = obj;
    this._changeDetectionRef.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public removeSelected($event): void {
    if ($event) {
      $event.stopPropagation();
    }
    this.writeValue(null);
    this.onChange(this.value);
    this.change.emit(this.value);
  }
}
