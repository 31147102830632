/**
 * Helper class to create valid SQLite queries.
 *
 * If no FROM-Table is provided, a '{{FROM_TABLE}}' placeholder will be used.
 * You will need to replace it, before proceeding with the real query.
 */
export class SQLiteQueryBuilder {
  private _selectFrom: string;
  private _select: string[] = [];
  private _where: string[] = [];
  private _orderBy: string;
  private _limit: number;
  private _offset: number;
  private _groupBy: string;
  private _join: string;
  private _fromAs: string;

  constructor() {}

  from(table: string, as?: string): SQLiteQueryBuilder {
    this._selectFrom = table.replace(/\.db$/, '');
    if (as != null && as != '') {
      this._fromAs = as;
    }
    return this;
  }

  fromFts(table: string, as?: string): SQLiteQueryBuilder {
    this._selectFrom = table.replace(/\.db$/, '_fts');
    if (as != null && as != '') {
      this._fromAs = as;
    }
    return this;
  }

  select(...columns: string[]): SQLiteQueryBuilder {
    this._select.push(...columns);
    return this;
  }

  selectAll(): SQLiteQueryBuilder {
    this._select.push('*');
    return this;
  }

  orderBy(column: string, direction: string): SQLiteQueryBuilder {
    this._orderBy = `${column} ${direction}`;
    return this;
  }

  limit(limit: number) {
    this._limit = limit;
    return this;
  }

  offset(offset: number) {
    this._offset = offset;
    return this;
  }

  groupBy(column: string): SQLiteQueryBuilder {
    this._groupBy = column;
    return this;
  }

  where(column: string, operator: SqlComparisonOperator, condition: SqlConditionValue): SQLiteQueryBuilder {
    this._where.push(new Condition(column, operator, condition).toString());
    return this;
  }

  and(column: string, operator: SqlComparisonOperator, condition: SqlConditionValue): SQLiteQueryBuilder {
    if (this._where.length > 0) {
      this._where.push(`AND ${new Condition(column, operator, condition).toString()}`);
    } else {
      this._where.push(new Condition(column, operator, condition).toString());
    }
    return this;
  }

  // search for values in a sqlite numbers array like "[12,65,2]"
  andInNumbersArray(column: string, values: any[]): SQLiteQueryBuilder {
    const formattedValues = values.toString();

    const existsClause = `EXISTS (SELECT 1 FROM json_each(${column}) WHERE json_each.value IN (${formattedValues}))`;

    if (this._where.length > 0) {
      this._where.push(`AND ${existsClause.trim()}`);
    } else {
      this._where.push(existsClause.trim());
    }

    return this;
  }

  or(column: string, operator: SqlComparisonOperator, condition: SqlConditionValue): SQLiteQueryBuilder {
    if (this._where.length > 0) {
      this._where.push(`OR ${new Condition(column, operator, condition).toString()}`);
    } else {
      this._where.push(new Condition(column, operator, condition).toString());
    }
    return this;
  }

  andGroup(subQuery: SQLiteQueryBuilder): SQLiteQueryBuilder {
    if (this._where.length > 0) {
      this._where.push(`AND (${subQuery._where.join(' ')})`);
    } else {
      this._where.push(`(${subQuery._where.join(' ')})`);
    }
    return this;
  }

  orGroup(subQuery: SQLiteQueryBuilder): SQLiteQueryBuilder {
    if (this._where.length > 0) {
      this._where.push(`OR (${subQuery._where.join(' ')})`);
    } else {
      this._where.push(`(${subQuery._where.join(' ')})`);
    }
    return this;
  }

  innerJoin(table: string, fromTableKey: string, operator: string, joinTableKey: string): SQLiteQueryBuilder {
    table = table.replace(/\.db$/, '');

    this._join = `${this._join ?? ''} INNER JOIN [${table}] ON [${
      this._selectFrom
    }].${fromTableKey} ${operator} [${table}].${joinTableKey}`.trim();
    return this;
  }

  asString(): string {
    const statement = `SELECT ${this._select.map(value => `${value}`).join(',')} FROM [${
      this._selectFrom ?? '{{FROM_TABLE}}'
    }]${this._fromAs ? ` AS ${this._fromAs}` : ''}${this._join ? ` ${this._join}` : ''}${
      this._where.length ? ` WHERE ${this._where.join(' ')}` : ''
    }${this._orderBy ? ` ORDER BY ${this._orderBy}` : ''}${this._limit ? ` LIMIT ${this._limit}` : ''}${
      this._offset ? ` OFFSET ${this._offset}` : ''
    }${this._groupBy ? ` GROUP BY ${this._groupBy}` : ''}`;

    return statement;
  }
}

export type SqlComparisonOperator =
  | '='
  | '<>'
  | '!='
  | '<='
  | '>='
  | '<'
  | '>'
  | 'IN'
  | 'NOT IN'
  | 'LIKE'
  | 'NOT LIKE'
  | 'IS NULL'
  | 'IS NOT NULL';

export type SqlConditionValue = any;
export class Condition {
  constructor(
    private column: string,
    private operator: SqlComparisonOperator,
    private condition?: SqlConditionValue
  ) {}

  public toString() {
    let conditionString = '';
    if (Array.isArray(this.condition)) {
      conditionString = `(${this.condition.map(condition => `'${condition}'`).join(',')})`;
    } else {
      conditionString = `'${this.condition}'`;
    }

    if (this.operator === 'IS NULL' || this.operator === 'IS NOT NULL') {
      return `${this.column} ${this.operator}`;
    } else {
      return `${this.column} ${this.operator} ${conditionString}`;
    }
  }
}
