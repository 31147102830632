import { Injectable } from '@angular/core';
import { LoadingController, Platform } from '@ionic/angular';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { ICurrentConfig } from 'cordova-plugin-ionic/dist/ngx/IonicCordova';
import { IAuthAction } from 'ionic-appauth';
import { AppflowChannel } from 'src/environments/appflow-channel';

@Injectable({ providedIn: 'root' })
export class AppflowLiveUpdatesService {
  public get currentDeployment(): string {
    const deploymentComponents = this._currentDeployment ? this._currentDeployment.split('_') : [];
    if (deploymentComponents.length > 1) {
      const platform = deploymentComponents[deploymentComponents.length - 1];

      return platform.toLocaleLowerCase();
    }

    return (this._currentDeployment ?? '').toLocaleLowerCase();
  }
  public deployInfo: ICurrentConfig;
  private _currentDeployment: string;
  constructor(
    private _platform: Platform,
    private _deploy: Deploy,
    private _loadingController: LoadingController,
    private readonly _appflowChannel: AppflowChannel
  ) {}

  public async switchDeployment(channel: string): Promise<string> {
    await this._appflowChannel.ready;
    await this.setChannel(this._appflowChannel.resetChannelName(channel));

    const checkForUpdateResponse = await this._deploy.checkForUpdate();
    if (!checkForUpdateResponse.available) {
      console.error(
        `No compatible update available in channel ${channel}. Check binary version.`,
        new Error('AppflowLiveUpdates Error')
      );
      return;
    }
    await this.performManualUpdate();
    return;
  }

  public async sync() {
    await this._platform.ready();

    if (!this._platform.is('hybrid')) {
      return;
    }

    await this._deploy.sync({ updateMethod: 'background' });
  }

  public async checkChannel(): Promise<string> {
    try {
      await this._platform.ready();

      if (!this._platform.is('hybrid')) {
        return;
      }

      this.deployInfo = await this._deploy.getConfiguration();
      this._currentDeployment = this.deployInfo.channel;

      return this.currentDeployment;
    } catch (err) {}
  }

  public async setChannel(channel?: string): Promise<void> {
    try {
      await this._platform.ready();
      await this._appflowChannel.ready;

      if (!this._platform.is('hybrid')) {
        return;
      }
      const channelToSet = channel ?? this._appflowChannel.channel;
      await this._deploy.configure({ channel: channelToSet });
      await this.checkChannel();
    } catch (error) {
      window.logger.error(`AppflowLiveUpdatesService.setChannel(${channel}) failed`, error);
    }
  }

  private async performManualUpdate() {
    return new Promise<void>((resolve, reject) => {
      this._loadingController
        .create({
          message: 'Starte Download',
        })
        .then(loading => {
          loading.present();
          this._deploy
            .sync({}, progress => {
              loading.message = `Wechsle zu Kanal ${this._currentDeployment} (${progress} / 100 %)`;
              if (progress === 100) {
                loading.dismiss();
                resolve();
              }
            })
            .catch(error => {
              loading.dismiss();
              console.error(`Failed to download Appflow Live Update for channel ${this._currentDeployment}.`, error);
              reject(error);
            });
        });
    });
  }

  public async onSignOutSuccess(_: IAuthAction) {
    await this._deploy.reloadApp();
  }
}
